/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-20 14:26:24
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { advertLink, advertDefault } from '@/utils/options';
import { getLabel } from '@/utils/options';
export var searchOption = [];



export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '图片',
    kname: 'imageUrl',
    component: {
      name: 'c-image' } },


  {
    label: '名称',
    kname: 'advertName' },

  {
    label: '创建时间',
    kname: 'createAt' },

  {
    label: '点击量',
    kname: 'hitsNumber' },

  {
    label: '默认标记',
    kname: 'isDefault',
    filterFn: function filterFn(v) {
      return getLabel(advertDefault, v);
    } },

  {
    label: '设置跳转的项目详情',
    kname: 'jupType',
    filterFn: function filterFn(v) {var _advertLink$v;
      return (_advertLink$v = advertLink[v]) === null || _advertLink$v === void 0 ? void 0 : _advertLink$v.label;
    } },

  {
    label: '上下架',
    kname: 'status',
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus },

      props: {
        // disabled() {
        //   return !ctx.hasPermission([58])
        // }
      } } },


  {
    label: '操作',
    kname: 'btns' }];};